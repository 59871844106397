@import "variables";
@import "mixins";
@import "basic";
@import "class";
@import "main-forms";
@import "carousel";
@import "static-pages";

app-login,
app-category,
app-change-password,
app-create-order,
app-edit-profile,
app-forgot-password,
app-sign-up,
app-profile,
app-home,
app-contact-us,
app-static,
app-payment-status,
app-sms-verification,
app-payment,
app-access-denied {
  flex-grow: 1;
}

shared-product-card {
  display: block;
  height: 100%;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  // stroke: #ff4e4e;
}

html,
body {
  height: 100%;
  background: url("assets/images/icons/mask_group_1.svg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $bg;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.contact-us {
  .contact-info {
    padding: $ms-padding 0;
    background-color: #eafffe;

    .row {
      display: flex;

      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }
    }

    .info {
      width: 55%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
        width: 100%;
      }

      p {
        text-align: center;
        margin-top: $base-margin;
      }

      .title {
        font-size: $title-size;

        @media (max-width: 768px) {
          font-size: $secondary-title;
        }
      }

      .contact-detail {
        color: $red-orange;
        font-size: $secondary-title;

        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
    }

    .banner {
      width: 45%;

      @media (max-width: 768px) {
        width: 50%;
        margin: auto;
      }

      @media (max-width: 568px) {
        width: 80%;
        margin: auto;
      }

      img {
        width: 100%;
      }
    }
  }

  .office {
    margin: $ms-margin 0;

    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (max-width: 512px) {
        display: block;
      }
    }

    .country {
      width: 30%;
      padding: $medium-padding 0;

      @media (max-width: 768px) {
        padding: $base-padding 0;
        width: 49%;
      }

      @media (max-width: 512px) {
        padding: $base-padding 0;
        width: 100%;
      }
    }

    .country-name {
      padding-top: 2 * $small-margin;
    }

    .address-title,
    .phone-title {
      color: $light-gray;
      margin-top: 16px;
      margin-bottom: $small-margin;
    }

    .address,
    .phone {
      color: $light-gray;
      font-size: 14px;
    }
  }
}

.prevArrowBtn {
  border-radius: 50px;
  top: 35%;
  // filter: invert(1);
}

.nextArrowBtn {
  border-radius: 50px;
  top: 35%;
  // filter: invert(1);
}

.mat-paginator {
  background-color: transparent;
  // color: #ffffff;

  .mat-select-value,
  .mat-select-value {
    // color: #ffffff;
  }

  .mat-icon-button.mat-button-disabled.mat-button-disabled {
    color: #7171713b !important;
  }

  .mat-select-arrow {
    // color: #ffffff;
  }
}

.login-page .row-buttons .mat-button-wrapper {
  max-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
}

.edit-profile .phone-row .mat-form-field-outline-end {
  border-radius: 0px;
}

.file-over {
  background-color: $overlay-background;
}

.edit-profile .date-field {
  mat-form-field-flex {
    padding-bottom: 0 !important;
  }
}

button.apple-button .mat-button-wrapper {
  margin: auto;
}

.category-slide {
  .product {
    padding: 0px 5px !important;

    @media (max-width:768px) {
      padding: 0 !important;
    }
  }
}

.server-image {
  width: 100px;
}

@media screen and (max-width: 768px) {
  #freshworks-container {
    iframe {
      bottom: 57px !important;
    }
  }
}

.mat-simple-snackbar-action .mat-button-wrapper {
  color: $white;
}